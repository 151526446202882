import { Td, Tr } from '@chakra-ui/react';

type CCTrErrorLoadingRowProps = {
  children: JSX.Element;
  colSpan: number;
};

export function CCTrErrorLoadingRow({
  children,
  colSpan,
}: CCTrErrorLoadingRowProps) {
  return (
    <Tr>
      <Td colSpan={colSpan} style={{ backgroundColor: 'transparent' }}>
        {children}
      </Td>
    </Tr>
  );
}
