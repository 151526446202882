import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { IconSearch } from '@tabler/icons-react';
import { memo, useCallback, useState } from 'react';

import { i18n } from '@cccom/shared/i18n';
import { useDebounce } from '@cccom/shared/utils/common';

type SearchProps = {
  onChange: (value: string) => void;
  value?: string;
  placeholder?: string;
};

function SearchFn({
  onChange,
  value,
  placeholder = i18n.t('common.labels.search'),
}: SearchProps) {
  const debounce = useDebounce(undefined, 500);

  const [searchValue, setSearchValue] = useState(value ?? '');
  const onSearch = useCallback(
    (search: string) => {
      setSearchValue(search);
      debounce(() => onChange(search));
    },
    [debounce]
  );

  return (
    <Flex minW="fit-content">
      <InputGroup>
        <InputRightElement pointerEvents="none">
          <Icon as={IconSearch} color="grey" boxSize="5" />
        </InputRightElement>
        <Input
          name="search"
          placeholder={placeholder}
          color="brand"
          value={searchValue}
          onChange={(e) => onSearch(e.target.value)}
          aria-label={i18n.t('common.labels.search')}
          data-cy="search"
        />
      </InputGroup>
    </Flex>
  );
}

export const Search = memo(SearchFn);
export default Search;
