import { Box, Skeleton } from '@chakra-ui/react';

import { EmptyState } from '../../empty-state/EmptyState';
import { CCTrErrorLoadingRow } from './CCTrErrorLoadingRow';

type TrErrorLoadingStatesProps = {
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
  isTableDataEmpty: boolean;
  emptyMessage: string | JSX.Element;
  colSpan: number;
  children: JSX.Element;
};

export function TrErrorLoadingStates({
  isLoading,
  isError,
  errorMessage,
  isTableDataEmpty,
  emptyMessage,
  colSpan,
  children,
}: TrErrorLoadingStatesProps) {
  if (isLoading) {
    return (
      <>
        {Array.from({ length: 10 }).map((_, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <CCTrErrorLoadingRow key={i} colSpan={colSpan}>
            <Box data-testid={i === 0 && 'skeleton-ui'}>
              <Skeleton height="4" />
            </Box>
          </CCTrErrorLoadingRow>
        ))}
      </>
    );
  }

  if (isError) {
    return (
      <CCTrErrorLoadingRow colSpan={colSpan}>
        {typeof emptyMessage === 'string' ? (
          <EmptyState errorDesc={errorMessage} />
        ) : (
          emptyMessage
        )}
      </CCTrErrorLoadingRow>
    );
  }

  if (isTableDataEmpty) {
    return (
      <CCTrErrorLoadingRow colSpan={colSpan}>
        {typeof emptyMessage === 'string' ? (
          <EmptyState errorDesc={emptyMessage} />
        ) : (
          emptyMessage
        )}
      </CCTrErrorLoadingRow>
    );
  }

  return children;
}
