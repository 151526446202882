import { useState, useLayoutEffect } from 'react';

export function useGetTableMaxHeight(offsetHeight: string) {
  const [maxHeight, setMaxHeight] = useState('');

  useLayoutEffect(() => {
    function updateMaxHeight() {
      const offsetHeightNum = parseFloat(offsetHeight);
      const topbarHeightNum = parseFloat(
        getComputedStyle(document.body).getPropertyValue('--topbar-height')
      );
      const tableOffsetNum = topbarHeightNum + offsetHeightNum - 100;
      const maxHeightNum = window.innerHeight - tableOffsetNum;

      if (maxHeightNum < tableOffsetNum) {
        setMaxHeight(`100%`);
        return;
      }

      const tableOffset = `calc(var(--topbar-height) + ${offsetHeight})`;
      setMaxHeight(`calc(100dvh - ${tableOffset})`);
    }
    window.addEventListener('resize', updateMaxHeight);
    updateMaxHeight();
    return () => window.removeEventListener('resize', updateMaxHeight);
  }, [offsetHeight]);

  return maxHeight;
}
