import { useCallback, useEffect, useRef, useState } from 'react';

const MAX_WIDTH = 390;

export const useOverflow = () => {
  const elementRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  const checkOverflow = useCallback(() => {
    if (!elementRef.current) return;
    setIsOverflowing(elementRef.current.offsetWidth >= MAX_WIDTH);
  }, []);

  useEffect(() => {
    const observer = new ResizeObserver(checkOverflow);
    const element = elementRef.current;

    if (element) {
      observer.observe(element);
      checkOverflow();
    }

    return () => observer.disconnect();
  }, [checkOverflow]);

  return { elementRef, isOverflowing, maxWidth: MAX_WIDTH };
};
