/* eslint-disable react/jsx-no-useless-fragment */
import { Text } from '@chakra-ui/react';

type TableCellTextProps = {
  value: unknown;
  cyTestId?: string;
};

export function TableCell({ value, cyTestId }: TableCellTextProps) {
  if (value === undefined || value === null || value === '')
    return <Text data-cy={cyTestId}>---</Text>;

  if (typeof value === 'string') return <Text data-cy={cyTestId}>{value}</Text>;

  return <>{value}</>;
}
