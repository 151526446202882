import baseTheme from '../../base-theme';

export const SysInventoryStatusColors = {
  IN_USE: {
    ...baseTheme['colors'].green,
    800: baseTheme['colors'].green['900'],
  },
  RETIRED: {
    ...baseTheme['colors'].red,
    800: baseTheme['colors'].red['900'],
  },
};
