import { Box, HStack, Icon } from '@chakra-ui/react';
import { IconAlertTriangle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';

type MissingDataProps = {
  'data-cy'?: string;
};

export function MissingData({ 'data-cy': dataTestId }: MissingDataProps) {
  const [t] = useTranslation();
  return (
    <HStack
      gap="1"
      as="span"
      data-testid="missing-data-formatter"
      data-cy={dataTestId}
    >
      <Box as="span" color="red.500">
        {t('common.labels.unknown')}
      </Box>
      <Icon as={IconAlertTriangle} color="red.500" boxSize="5" />
    </HStack>
  );
}
