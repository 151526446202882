import { extendTheme } from '@chakra-ui/react';
import baseTheme from '../../base-theme';

const rpcStatus = {
  FOR_REVIEW: {
    ...baseTheme['colors'].yellow,
    800: baseTheme['colors'].yellow['900'],
  },
  INCOMPLETE: {
    ...baseTheme['colors'].red,
    800: baseTheme['colors'].red['900'],
  },
  REVIEWED: {
    ...baseTheme['colors'].green,
    800: baseTheme['colors'].green['900'],
  },
  COMPLETE: {
    ...baseTheme['colors'].blue,
    800: baseTheme['colors'].blue['900'],
  },
};

const systemStatus = {
  IN_USE: {
    ...baseTheme['colors'].green,
    800: baseTheme['colors'].green['900'],
  },
  RETIRED: {
    ...baseTheme['colors'].red,
    800: baseTheme['colors'].red['900'],
  },
};

const theme = extendTheme({
  ...baseTheme,
  colors: {
    ...baseTheme['colors'],
    ...rpcStatus,
    ...systemStatus,
    brand: {
      ...baseTheme['colors'].teal,
      topbar: baseTheme['colors'].teal['300'],
      25: '#DAF1EF',
    },
  },
});

export default theme;
