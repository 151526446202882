import {
  Box,
  BoxProps,
  Checkbox,
  Table,
  TableContainer,
  Tbody,
  Td,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import {
  Cell,
  ColumnDef,
  ColumnFiltersState,
  createColumnHelper,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  Row,
  RowSelectionState,
  SortingFn,
  SortingState,
  TableOptions,
  useReactTable,
} from '@tanstack/react-table';
import {
  Dispatch,
  Fragment,
  memo,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { PaginationProps } from '@cccom/shared/data-access';
import { i18n } from '@cccom/shared/i18n';
import { TableVariants } from '@cccom/shared/themes';

import { CCTh } from './components/CCTh';
import { CCTr } from './components/CCTr';
import { RenderPagination } from './components/Pagination';
import { TableCell } from './components/TableCell';
import { TrErrorLoadingStates } from './components/TrErrorLoadingStates';
import { useGetTableMaxHeight } from './hooks/use-table-max-height';

type CCTableFilterProps = {
  globalFilter?: string;
  setGlobalFilter?: React.Dispatch<React.SetStateAction<string>>;
  columnFilters?: ColumnFiltersState;
  setColumnFilters?: React.Dispatch<React.SetStateAction<ColumnFiltersState>>;
};

export type CCTableProps<TData> = {
  columns: ColumnDef<TData, string>[]; // TODO research this type for use with columnHelpers - Github issue: https://github.com/TanStack/table/issues/4382 // https://tanstack.com/table/v8/docs/guide/column-defs#column-helpers. Tracked by CCCOM-4729
  data?: TData[];

  isError: boolean;
  isLoading?: boolean;
  errorMessage?: string;
  emptyMessage?: string | JSX.Element;

  defaultColumn?: Partial<ColumnDef<TData>>;
  defaultSortingState?: SortingState;

  uiFilters?: CCTableFilterProps;
  filters?: string; // to reset bulk selection on filter change
  sortingFns?: Record<any, SortingFn<unknown>>;
  paginationProps?: PaginationProps;
  serverSideSorting?: boolean;
  serverSidePagination?: boolean;

  variant?: TableVariants;
  offsetHeight?: string;
  selectedRowId?: string;
  enableExpanding?: boolean;
  disableRowClickForColumnId?: string[];

  bulkSelect?: boolean;
  rowSelection?: RowSelectionState;
  enableRowSelection?: TableOptions<TData>['enableRowSelection'];
  getRowId?: TableOptions<TData>['getRowId'];
  setRowSelection?: Dispatch<SetStateAction<RowSelectionState>>;

  onRowClick?: (row: { row: Row<TData>; cell: Cell<TData, unknown> }) => void;
  onSortingChanged?: (sort: SortingState) => void;
  renderSubComponent?: (data: TData) => JSX.Element;
};

const defaultColumnConfig: Partial<ColumnDef<any>> = {
  cell: (info) => <TableCell value={info.getValue()} />,
};

function getBulkColumn<TData>() {
  const columnHelper = createColumnHelper<TData>();
  return columnHelper.display({
    id: 'bulk',
    size: 30,
    header: ({ table }) => {
      let label = 'Select All';

      if (table.getIsSomeRowsSelected()) label = 'Select All';
      if (table.getIsAllRowsSelected()) label = 'Deselect All';

      return (
        <Tooltip hasArrow shouldWrapChildren placement="top" label={label}>
          <Checkbox
            variant="thin"
            isChecked={table.getIsAllRowsSelected()}
            isIndeterminate={table.getIsSomeRowsSelected()}
            onChange={table.getToggleAllRowsSelectedHandler()}
          />
        </Tooltip>
      );
    },
    cell: ({ row }) => {
      return (
        <Checkbox
          variant="thin"
          disabled={!row.getCanSelect()}
          isChecked={row.getIsSelected()}
          isIndeterminate={row.getIsSomeSelected()}
          onChange={row.getToggleSelectedHandler()}
        />
      );
    },
  });
}

function CCTableFn<TData>({
  columns,
  data,

  isError = false,
  isLoading = false,
  errorMessage = i18n.t('common.errors.error_fetching_data') ??
    'An error occurred while fetching the data.',
  emptyMessage = i18n.t('common.errors.empty_state_msg') ??
    'Nothing to display.',

  defaultColumn = defaultColumnConfig,
  defaultSortingState = [],

  uiFilters,
  filters,
  sortingFns = {},
  paginationProps,
  serverSideSorting = false,
  serverSidePagination = false,

  variant = 'striped',
  offsetHeight = '273px',
  selectedRowId,
  enableExpanding = false,
  disableRowClickForColumnId = ['bulk'],

  bulkSelect,
  rowSelection,
  enableRowSelection,
  getRowId,
  setRowSelection,

  onRowClick,
  onSortingChanged,
  renderSubComponent,
}: CCTableProps<TData>) {
  const memoizedData = useMemo(() => data ?? [], [data]);
  const memoizedColumns = useMemo(() => {
    if (!bulkSelect) return columns;

    const bulkColumn = getBulkColumn<TData>();
    return [bulkColumn, ...columns];
  }, [bulkSelect, columns]);

  const [sorting, setSorting] = useState(defaultSortingState);

  useEffect(() => {
    if (onSortingChanged && serverSideSorting) onSortingChanged(sorting);
    // disabling because we only want to make the check when sorting changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sorting]);

  const tableOptions: TableOptions<TData> = useMemo(
    () => ({
      data: memoizedData,
      columns: memoizedColumns,
      defaultColumn,
      sortingFns,
      enableFilters: !!uiFilters,
      globalFilterFn: 'includesString',
      manualPagination: serverSidePagination,
      manualSorting: serverSideSorting,
      state: { sorting, rowSelection, ...uiFilters },
      enableRowSelection: enableRowSelection || bulkSelect,

      getRowId,
      onRowSelectionChange: setRowSelection,
      onSortingChange: setSorting,
      getCoreRowModel: getCoreRowModel(),
      getSortedRowModel: getSortedRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      getRowCanExpand: () => enableExpanding,
      getExpandedRowModel: getExpandedRowModel(),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      memoizedData,
      memoizedColumns,
      sorting,
      rowSelection,
      uiFilters,
      bulkSelect,
      enableRowSelection,
      serverSidePagination,
      serverSideSorting,
    ]
  );

  const table = useReactTable(tableOptions);
  const maxHeight = useGetTableMaxHeight(offsetHeight);

  const { rows } = table.getFilteredRowModel();
  const isTableDataEmpty = useMemo(
    () => !data || !data.length || !rows.length,
    [data, rows.length]
  );

  // Reset bulk select on pagination, sort or filter change
  useEffect(() => {
    if (bulkSelect) table.resetRowSelection();
  }, [
    bulkSelect,
    filters,
    sorting,
    paginationProps?.paginationState?.pageIndex,
    paginationProps?.paginationState?.pageSize,
    table,
  ]);

  const boxStyle: BoxProps = !variant.toLowerCase().includes('drawer')
    ? {
        padding: '2',
        borderWidth: '0.5px',
        borderColor: 'gray.300',
        borderRadius: 'md',
      }
    : {};

  return (
    <Box {...boxStyle}>
      <TableContainer
        overflowY="auto"
        maxH={maxHeight}
        css={{
          scrollbarGutter: 'stable both-edges',
          '&::-webkit-scrollbar': {
            width: '7px',
            height: '7px',
          },
          '&::-webkit-scrollbar-thumb': {
            background: 'var(--chakra-colors-gray-400)',
            borderRadius: '24px',
          },
        }}
      >
        <Table variant={variant}>
          <Thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <Tr position="sticky" top="0" zIndex="1" key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  const sortable = header.column.columnDef.enableSorting;

                  return (
                    <CCTh header={header} sortable={sortable} key={header.id} />
                  );
                })}
              </Tr>
            ))}
          </Thead>
          <Tbody>
            <TrErrorLoadingStates
              isError={isError}
              isLoading={isLoading}
              errorMessage={errorMessage}
              emptyMessage={emptyMessage}
              isTableDataEmpty={isTableDataEmpty}
              colSpan={table.getAllColumns().length}
            >
              <>
                {table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <CCTr
                      row={row}
                      selectedRowId={selectedRowId}
                      disableRowClickForColumnId={disableRowClickForColumnId}
                      onRowClick={onRowClick}
                    />

                    {row.getIsExpanded() && renderSubComponent && (
                      <Tr
                        className="sub-component"
                        data-testid="tableSubComponent"
                      >
                        <Td colSpan={row.getVisibleCells().length} p="0">
                          {renderSubComponent(row.original)}
                        </Td>
                      </Tr>
                    )}
                  </Fragment>
                ))}
              </>
            </TrErrorLoadingStates>
          </Tbody>
        </Table>
      </TableContainer>

      {paginationProps && (
        <RenderPagination
          table={table}
          isServerSide={serverSidePagination}
          paginationProps={paginationProps}
        />
      )}
    </Box>
  );
}

export const CCTable = memo(CCTableFn) as typeof CCTableFn;
