import {
  Button,
  Flex,
  HStack,
  Icon,
  Select,
  Spacer,
  Text,
} from '@chakra-ui/react';
import { useReactTable } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import { TbChevronLeft, TbChevronRight } from 'react-icons/tb';

import { PaginationProps } from '@cccom/shared/data-access';

export function Pagination({
  setPageSize,
  nextPage,
  getCanNextPage,
  previousPage,
  getCanPreviousPage,
  filteredRowsLength,
  paginationState,
}: PaginationProps) {
  const [t] = useTranslation();

  const showPageStatus = () => {
    if (!paginationState) return '';
    const { pageIndex, pageSize } = paginationState;
    const pageNumber = pageIndex + 1;
    const firstRow = pageIndex * pageSize + 1;
    const lastRow = pageNumber * pageSize;

    if (lastRow > filteredRowsLength) {
      return `${firstRow}-${filteredRowsLength}`;
    }
    return `${firstRow}-${lastRow}`;
  };

  return (
    <Flex
      p="5"
      data-testid="pagination-ui"
      color="gray.700"
      fontWeight="medium"
      fontSize="sm"
    >
      <HStack gap="4">
        <Text>{t('pagination.show_rows_per_page')}</Text>
        <Select
          name="select rows"
          w="fit-content"
          aria-label="select rows"
          rounded="lg"
          fontSize="sm"
          iconColor="gray.500"
          cursor="pointer"
          focusBorderColor="brand.500"
          value={paginationState?.pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 20, 30, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </Select>
      </HStack>

      <Spacer />

      <HStack spacing="10" fontSize="sm">
        <HStack spacing="2">
          <Text color="gray.700">{showPageStatus()}</Text>
          <Text color="gray.400">
            {t('common.others.of')} {filteredRowsLength}
          </Text>
        </HStack>
        <HStack spacing="2">
          <Button
            variant="iconNoBorder"
            bg="transparent"
            aria-label="previous page"
            color="gray.700"
            onClick={previousPage}
            isDisabled={!getCanPreviousPage()}
          >
            <Icon boxSize="4" as={TbChevronLeft} />
          </Button>

          <Button
            variant="iconNoBorder"
            bg="transparent"
            aria-label="next page"
            color="gray.700"
            onClick={nextPage}
            isDisabled={!getCanNextPage()}
          >
            <Icon boxSize="4" as={TbChevronRight} />
          </Button>
        </HStack>
      </HStack>
    </Flex>
  );
}

type RenderPaginationProps<Data> = {
  table: ReturnType<typeof useReactTable<Data>>;
  isServerSide: boolean;
  paginationProps?: PaginationProps;
};

export function RenderPagination<Data>({
  table,
  isServerSide,
  paginationProps,
}: RenderPaginationProps<Data>) {
  let props: PaginationProps = {
    nextPage: table.nextPage,
    previousPage: table.previousPage,
    getCanNextPage: table.getCanNextPage,
    getCanPreviousPage: table.getCanPreviousPage,
    filteredRowsLength: table.getFilteredRowModel().rows.length,
    setPageSize: table.setPageSize,
    paginationState: table.getState().pagination,
  };

  if (isServerSide && paginationProps) props = paginationProps;

  return <Pagination {...props} />;
}
