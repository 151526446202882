import { useToken } from '@chakra-ui/react';
import { useCallback } from 'react';

import { App, AppIds } from '@cccom/config/app-type';

import {
  useAppsList,
  useGetConfig,
  useHelpCenter,
  useTimeZone,
  useWizardSettings,
} from './api';
import { ConfigSettings, ContentMaxWidth } from './types';

type UseSettingsProps = {
  maxWidth?: ContentMaxWidth;
};

type SettingsState = {
  timezone: string;
  helpCenterUrl: string;
  appList: App[];
  contentMaxWidth: string;
  wizardSettings: { skip: boolean };
  configSettings: ConfigSettings;
  isAppActive: (appId: AppIds) => boolean;
};

export function useSettings(props?: UseSettingsProps): SettingsState {
  const [contentMaxWidth] = useToken('breakpoints', [props?.maxWidth ?? 'xl']);
  const { data: timezone } = useTimeZone();
  const { data: helpCenter } = useHelpCenter();
  const { data: appList } = useAppsList();
  const { data: wizardSettings } = useWizardSettings();
  const { data: configSettings } = useGetConfig();

  const isAppActive = useCallback(
    (appId: AppIds) => {
      return !!appList?.find((app) => app.id === appId);
    },
    [appList]
  );

  const settings: SettingsState = {
    timezone: timezone?.timezone ?? 'UTC',
    helpCenterUrl: helpCenter?.url ?? '',
    appList: appList ?? [],
    contentMaxWidth,
    wizardSettings: wizardSettings ?? { skip: false },
    configSettings: configSettings ?? {
      enableSOPId: false,
      mandatoryQAReview: false,
      enableComments: false,
    },
    isAppActive,
  };

  return settings;
}
