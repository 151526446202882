import { Flex, Icon, Text } from '@chakra-ui/react';
import { ReactNode } from 'react';
import { TbFileAlert } from 'react-icons/tb';

export type EmptyStateProps = {
  errorDesc: string;
  children?: ReactNode;
};

export function EmptyState({ errorDesc, children }: EmptyStateProps) {
  return (
    <Flex
      p="7"
      minH="25vh"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Icon boxSize="4em" as={TbFileAlert} color="brand.400" />
      <Text mt="3" size="md" color="#42526E" data-cy="empty-state-pane-text">
        {errorDesc}
      </Text>
      {children}
    </Flex>
  );
}
