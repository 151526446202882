import { useMemo } from 'react';

type CallbackWithAnyParams = (...args: any) => void;

/**
 * https://www.joshwcomeau.com/snippets/javascript/debounce/
 *
 * @param callback any callback function
 * @param wait debounce duration in milliseconds
 * @returns a function that schedules a new timeout when called, and only triggers the callback function after the timeout expires
 */
function debounce(callback: CallbackWithAnyParams, wait?: number) {
  let timeoutId: number | undefined;
  return (...args: any) => {
    window.clearTimeout(timeoutId);

    timeoutId = window.setTimeout(() => callback(...args), wait ?? 300);
  };
}

const defaultDebounceCallback = (fn: CallbackWithAnyParams, value?: any) => {
  if (value) fn(value);
  else fn();
};

/**
 * @param callback any callback function
 * @param wait debounce duration in milliseconds
 * @returns a function that schedules a new timeout when called, and only triggers the callback function after the timeout expires
 */
export function useDebounce(callback?: CallbackWithAnyParams, wait?: number) {
  return useMemo(
    () => debounce(callback ?? defaultDebounceCallback, wait),
    [callback, wait]
  );
}
