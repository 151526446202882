/* eslint-disable react/jsx-no-useless-fragment */
import { Tooltip } from '@chakra-ui/react';
import { memo, ReactNode } from 'react';

import { MissingData } from '../table/components/MissingData';

type CanEditPermissions = {
  label: string;
  canEdit: boolean;
  extraCheck?: boolean;
};

export type NoDataFormatterProps = {
  children: ReactNode;
  data?: string;
  checkPermissions?: CanEditPermissions;
  'data-cy'?: string;
};

export const NoDataFormatter = memo(function NoDataFormatter({
  data,
  children,
  checkPermissions,
  ...rest
}: NoDataFormatterProps) {
  if (!checkPermissions)
    return data ? <>{children}</> : <MissingData data-cy={rest['data-cy']} />;

  const { extraCheck, canEdit, label } = checkPermissions;
  const hasDataOrExtraCheck = extraCheck || !data;
  const hasPermissions = canEdit && hasDataOrExtraCheck;

  return (
    <Tooltip placement="auto" label={hasPermissions && label}>
      {data ? (
        children
      ) : (
        <span>
          <MissingData data-cy={rest['data-cy']} />
        </span>
      )}
    </Tooltip>
  );
});
