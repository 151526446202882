import { Box, Flex, Th } from '@chakra-ui/react';
import { Header, flexRender } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';

import { ColumnSortIcons } from './ColumnSortIcons';

type CCThProps<TData> = {
  header: Header<TData, unknown>;
  sortable?: boolean;
};

export function CCTh<TData>({ header, sortable }: CCThProps<TData>) {
  const isSortableCursor = useMemo(
    () => (sortable ? 'pointer' : 'default'),
    [sortable]
  );

  const toggleSort = useCallback(() => {
    if (!sortable) return;
    header.column.toggleSorting();
  }, [header.column, sortable]);

  return (
    <Th
      p="3"
      bg="white"
      maxW="250px"
      userSelect="none"
      data-cy="ccTableHeaderCell"
      role="group"
      key={header.id}
      cursor={isSortableCursor}
    >
      <Flex alignItems="center">
        <Box onClick={toggleSort}>
          {header.isPlaceholder
            ? null
            : flexRender(header.column.columnDef.header, header.getContext())}
        </Box>

        {sortable && (
          <ColumnSortIcons
            isSorted={header.column.getIsSorted()}
            clearSorting={header.column.clearSorting}
            toggleSorting={header.column.toggleSorting}
          />
        )}
      </Flex>
    </Th>
  );
}
