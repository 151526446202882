import { format } from 'date-fns';

export const formatDate = (date: string, dateFormat: string) =>
  format(new Date(date), dateFormat);

export const camelToSnakeCase = (str: string) =>
  str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`);

export const snakeToCamelCase = (str: string) =>
  str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());

export const arrayToObject = (arr: string[]) =>
  Object.fromEntries(arr.map((arrItem, index) => [index, arrItem]));

export const splitByCapitalLetter = (str: string) =>
  str.split(/(?<![A-Z])(?=[A-Z])/).join(' ');

/**
 * Deep object comparison
 *
 * @param obj1
 * @param obj2
 * @returns true if they are equal, false if not
 */
export const objectsEqual = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
): boolean => {
  if (typeof obj1 === 'object' && Object.keys(obj1).length > 0) {
    return (
      Object.keys(obj1).length === Object.keys(obj2).length &&
      Object.keys(obj1).every((p) => objectsEqual(obj1[p], obj2[p]))
    );
  }

  return obj1 === obj2;
};

/**
 * Array comparison, that also supports arrays of object
 *
 * @param arr1
 * @param arr2
 * @returns true if they are equal, false if not
 */
export const arraysEqual = (arr1: any[], arr2: any[]) => {
  return (
    arr1.length === arr2.length &&
    arr1.every((o, idx) => objectsEqual(o, arr2[idx]))
  );
};
