import { Tr } from '@chakra-ui/react';
import { Cell, flexRender, Row } from '@tanstack/react-table';
import { useCallback, useMemo } from 'react';
import { CCTd } from './CCTd';

type CCTrProps<TData> = {
  row: Row<TData>;
  selectedRowId?: string;
  disableRowClickForColumnId?: string[];
  onRowClick?: (props: { row: Row<TData>; cell: Cell<TData, unknown> }) => void;
};

export function CCTr<TData>({
  row,
  selectedRowId,
  disableRowClickForColumnId,
  onRowClick,
}: CCTrProps<TData>) {
  const isRowClickableCursor = useMemo(
    () => (onRowClick ? 'pointer' : 'default'),
    [onRowClick]
  );

  const onRowClickHandler = useCallback(
    (cell: Cell<TData, unknown>) => {
      if (disableRowClickForColumnId?.includes(cell.column.id)) return;
      onRowClick?.({ row, cell });
    },
    [disableRowClickForColumnId, onRowClick, row]
  );

  return (
    <Tr
      data-cy="ccTableRow"
      data-testid="tableDataRow"
      data-selected={row.id === selectedRowId}
    >
      {row.getVisibleCells().map((cell) => {
        const {
          size,
          maxSize,
          minSize,
          cell: colDefCell,
        } = cell.column.columnDef;

        return (
          <CCTd
            p="3"
            key={cell.id}
            w={`${size}px`}
            maxW={`${maxSize}px`}
            minW={`${minSize}px`}
            cursor={isRowClickableCursor}
            onClick={() => onRowClickHandler(cell)}
          >
            {flexRender(colDefCell, cell.getContext())}
          </CCTd>
        );
      })}
    </Tr>
  );
}
