import {
  Button,
  ButtonProps,
  Flex,
  Icon,
  Spinner,
  Tooltip,
} from '@chakra-ui/react';
import { memo, ReactElement } from 'react';

import { useTranslation } from 'react-i18next';
import { TbDownload } from 'react-icons/tb';

export type ExportButtonProps = {
  tooltipLabel: string;
  isExporting?: boolean;
  onExportClick: () => void;
  showLabel?: boolean;
  exportIcon?: ReactElement;
  isDisabled?: boolean;
  variant?: ButtonProps['variant'];
  tooltipPlacement?: 'top' | 'bottom' | 'left' | 'right';
};

export const ExportButton = memo(function ExportButton({
  tooltipLabel,
  isExporting,
  onExportClick,
  showLabel = true,
  exportIcon = <Icon as={TbDownload} boxSize="5" />,
  isDisabled = isExporting,
  tooltipPlacement,
  variant = 'outline',
}: ExportButtonProps) {
  const [t] = useTranslation();

  return (
    <Tooltip label={tooltipLabel} placement={tooltipPlacement} m="2">
      <Button
        variant={variant}
        aria-label={t('common.labels.export')}
        isDisabled={isDisabled}
        onClick={onExportClick}
      >
        <Flex gap="2" alignItems="center" justifyContent="center">
          {showLabel && t('common.labels.export')}
          {isExporting ? (
            <Spinner data-testid="spinner" boxSize="4" p="2" color="gray.500" />
          ) : (
            exportIcon
          )}
        </Flex>
      </Button>
    </Tooltip>
  );
});
