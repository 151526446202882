import {
  Box,
  Flex,
  PlacementWithLogical,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { memo, ReactNode } from 'react';
import { MdInfo } from 'react-icons/md';

export type CCTooltipProps = {
  label: string | ReactNode;
  tooltipLabel: string | ReactNode;
  tooltipIcon?: ReactNode;
  placement?: PlacementWithLogical;
  hasArrow?: boolean;
  width?: string;
};

const CCTooltip = memo(function CCTooltip({
  label,
  tooltipLabel,
  placement = 'top',
  hasArrow,
  width,
  tooltipIcon = <MdInfo />,
}: CCTooltipProps) {
  return (
    <Flex gap="2" alignItems="center">
      {typeof label === 'string' ? <Text>{label}</Text> : label}

      {tooltipLabel && (
        <Tooltip
          label={tooltipLabel}
          placement={placement}
          hasArrow={hasArrow}
          w={width}
        >
          <Box color="blackAlpha.400" fontSize="lg" data-testid="tooltip">
            {tooltipIcon}
          </Box>
        </Tooltip>
      )}
    </Flex>
  );
});

export default CCTooltip;
