import {
  Box,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import { SortDirection } from '@tanstack/react-table';
import { useTranslation } from 'react-i18next';
import {
  TbArrowDown,
  TbArrowUp,
  TbArrowsSort,
  TbCircleOff,
  TbDotsVertical,
} from 'react-icons/tb';

type ColumnSortIconsProps = {
  isSorted: false | SortDirection;
  clearSorting: VoidFunction;
  toggleSorting: (desc?: boolean, isMulti?: boolean) => void;
};

function SortIcon({ isSorted }: Pick<ColumnSortIconsProps, 'isSorted'>) {
  switch (isSorted) {
    case 'desc':
      return <TbArrowDown aria-label="sorted descending" />;
    case 'asc':
      return <TbArrowUp aria-label="sorted ascending" />;
    case false:
      return <TbArrowsSort color="gray" aria-label="sortable" />;
    default:
      throw new Error(`${isSorted} is not a valid SortDirection`);
  }
}

export function ColumnSortIcons({
  isSorted,
  clearSorting,
  toggleSorting,
}: ColumnSortIconsProps) {
  const [t] = useTranslation();

  return (
    <HStack w="full" alignItems="center" gap="2">
      <Tooltip placement="top" label={t('common.actions.sort')}>
        <Box
          p="2"
          ml="2"
          as="button"
          data-cy="sortIcon"
          borderRadius="full"
          visibility={isSorted === false ? 'hidden' : 'visible'}
          _hover={{ background: 'gray.100' }}
          _groupHover={{ visibility: 'visible' }}
          onClick={() => toggleSorting()}
        >
          <SortIcon isSorted={isSorted} />
        </Box>
      </Tooltip>

      <Menu closeOnSelect>
        <IconButton
          isRound
          p="2"
          size="xs"
          variant="none"
          aria-label="Column menu"
          visibility="hidden"
          as={MenuButton}
          icon={<TbDotsVertical />}
          _hover={{ background: 'gray.100' }}
          _groupHover={{ visibility: 'visible' }}
        />

        <MenuList>
          {(isSorted === 'desc' || isSorted === false) && (
            <MenuItem onClick={() => toggleSorting(false)}>
              <HStack gap="2">
                <TbArrowUp aria-label="Sort ascending" />
                <Text>{t('common.actions.sort_by_asc')}</Text>
              </HStack>
            </MenuItem>
          )}
          {(isSorted === 'asc' || isSorted === false) && (
            <MenuItem onClick={() => toggleSorting(true)}>
              <HStack gap="2">
                <TbArrowDown aria-label="Sort descending" />
                <Text>{t('common.actions.sort_by_desc')}</Text>
              </HStack>
            </MenuItem>
          )}
          {isSorted !== false && (
            <MenuItem onClick={() => clearSorting()}>
              <HStack gap="2">
                <TbCircleOff aria-label="Clear sort" />
                <Text>{t('common.actions.clear_sort')}</Text>
              </HStack>
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </HStack>
  );
}
