import { Box, Flex, TableCellProps, Td } from '@chakra-ui/react';
import { ReactNode, memo } from 'react';

import { useOverflow } from '../hooks/use-overflow';

export const CCTd = memo(function CCTd({
  children,
  ...rest
}: { children: ReactNode } & TableCellProps) {
  const { elementRef, isOverflowing, maxWidth } = useOverflow();

  return (
    <Td {...rest}>
      <Flex data-cy="ccTableRowCell" data-testid="tableDataRowData">
        <Box overflow="hidden" as="span" maxW={maxWidth} ref={elementRef}>
          {children}
        </Box>
        {isOverflowing && (
          <Box as="span" ml="0.5">
            ...
          </Box>
        )}
      </Flex>
    </Td>
  );
});
